import React from "react"
import Cookie from "../../../components/body/pages/en-ng/legal/cookie/index"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const CookiePage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/cookie-policy"}
      title="Cookie Policy | Kuda | The Money App for Africans"
    />
    <Cookie />
  </Layout>
)

export default CookiePage
